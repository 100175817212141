import {NgModule} from '@angular/core';
import {VisitorFavoritesFacadeService} from './application/visitor-favorites-facade.service';
import {PhotographerFavoritesFacadeService} from './application/photographer-favorites-facade.service';
import {FavoritesDataService} from './infrastructure/favorites-data.service';
import {SaPingDataService} from '@px/shared/data-access/sa-data-ping';

@NgModule({
  providers: [
    FavoritesDataService,
    VisitorFavoritesFacadeService,
    PhotographerFavoritesFacadeService,
    SaPingDataService,
  ],
})
export class FavoritesDomainModule {}

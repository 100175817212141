import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {Transform, Type, plainToInstance} from 'class-transformer';
import dayjs, {Dayjs} from 'dayjs';
import {ContactInfo, IContactInfo, IContactInfoMultiEntry} from './contact-info.interface';
import {IProfile, Profile} from './profile.interface';
import {ISocialLinks, SocialLinks} from './social-links.interface';

export interface IAccount {
  email: string;
  profile: IProfile;
  pxId: number;
  pxr: string;
  isActive: boolean;
  dateCreated: string;
}

export interface IAccountSubscriptionInfo extends IAccount {
  billingInfo?: IBillingInfo;
}

export enum BillingSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  FUTURE = 'FUTURE',
  IN_TRIAL = 'IN_TRIAL',
  NON_RENEWING = 'NON_RENEWING',
  PAUSED = 'PAUSED',
}

export interface IBillingInfo {
  status: BillingSubscriptionStatus;
  package: {
    products: {
      price: {
        product: {
          id: string;
          name: string;
        };
        billingProductFamily: {
          productFamily: ProductFamily;
        };
      };
    }[];
  };
}

export interface IUserBillingInfo {
  id: string;
  email: string;
  name: string;
  created: Dayjs;
  AABPlan: string;
  SAPlan: string;
  PSFPlan: string;
  PSSPlan: string;
  CPPlan: string;
}

export class Account {
  private static trialPSSProductId = 'trial_product_id__PSS';
  private static trialPSFProductId = 'trial_product_id__PSF';

  email!: string;

  @Type(() => Profile)
  profile!: Profile;
  pxId!: number;
  pxr!: string;
  isActive!: boolean;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateCreated!: Dayjs;

  subscriptions?: Record<ProductFamily, IBillingInfo[]>;

  get fullName(): string {
    return `${this.profile.firstName} ${this.profile.lastName}`;
  }

  get contactInfo(): ContactInfo {
    return plainToInstance(ContactInfo, this.profile.metaData['contactInfo']);
  }

  get socialLinks(): SocialLinks {
    return {
      facebook: this.profile.metaData['facebook'] as string,
      instagram: this.profile.metaData['instagram'] as string,
      pinterest: this.profile.metaData['pinterest'] as string,
      tiktok: this.profile.metaData['tiktok'] as string,
      twitter: this.profile.metaData['twitter'] as string,
      contactEmail: this.profile.metaData['contactEmail'] as string,
    };
  }

  extends<T>(data: T): Account & T {
    return plainToInstance(Account, {...this, ...data}) as Account & T;
  }

  getSocialLinks(): ISocialLinks {
    return {
      facebook: this.socialLinks.facebook,
      instagram: this.socialLinks.instagram,
      pinterest: this.socialLinks.pinterest,
      tiktok: this.socialLinks.tiktok,
      twitter: this.socialLinks.twitter,
      contactEmail: this.socialLinks.contactEmail,
    };
  }

  getContactInfo(): IContactInfo {
    const phoneEntries = this.contactInfo?.phone.map(entry => entry.getCopy());
    const websiteEntries = this.contactInfo?.website.map(entry => entry.getCopy());
    const emailEntries = this.contactInfo?.email.map(entry => entry.getCopy());

    return {
      isAllRightReservedPresent: this.contactInfo?.isAllRightReservedPresent ?? false,
      isRightPlaceholderEnabled: this.contactInfo?.isRightPlaceholderEnabled ?? false,
      isVCardAttachedToEmail: this.contactInfo?.isVCardAttachedToEmail ?? false,
      isVCardEnabled: this.contactInfo?.isVCardEnabled ?? false,
      address: this.contactInfo?.address ?? '',
      address2: this.contactInfo?.address2 ?? '',
      avatarUrl: this.contactInfo?.avatarUrl ?? '',
      companyName: this.contactInfo?.companyName ?? '',
      country: this.contactInfo?.country ?? '',
      copyRightPlaceholder: this.contactInfo?.copyRightPlaceholder ?? '',
      firstName: this.contactInfo?.firstName ?? '',
      jobTitle: this.contactInfo?.jobTitle ?? '',
      lastName: this.contactInfo?.lastName ?? '',
      note: this.contactInfo?.note ?? '',
      postalCode: this.contactInfo?.postalCode ?? '',
      state: this.contactInfo?.state ?? '',
      email: emailEntries?.length ? emailEntries : [{label: '', value: ''}],
      phone: phoneEntries?.length ? phoneEntries : [{label: '', value: ''}],
      website: websiteEntries?.length ? websiteEntries : [{label: '', value: ''}],
    };
  }

  hasContactEmails(): boolean {
    return !!this.contactInfo?.email?.length;
  }

  getContactEmailList(): IContactInfoMultiEntry[] {
    return this.contactInfo?.email.map(entry => entry.getCopy()) ?? [];
  }

  hasContactWebsite(): boolean {
    return !!this.contactInfo?.website?.length;
  }

  getContactWebsiteList(): IContactInfoMultiEntry[] {
    return this.contactInfo?.website.map(entry => entry.getCopy()) ?? [];
  }

  hasContactPhone(): boolean {
    return !!this.contactInfo?.phone?.length;
  }

  getContactPhoneList(): IContactInfoMultiEntry[] {
    return this.contactInfo?.phone.map(entry => entry.getCopy()) ?? [];
  }

  getSubscriptionNameByProductFamily(productFamily: ProductFamily): string {
    return this.subscriptions?.[productFamily]?.[0]?.package?.products?.[0]?.price?.product?.name ?? '';
  }

  getSubscriptionProductIdByProductFamily(productFamily: ProductFamily): string {
    return this.subscriptions?.[productFamily]?.[0]?.package?.products?.[0]?.price?.product?.id ?? '';
  }

  isTrialProductFamily(productFamily: ProductFamily): boolean {
    switch (productFamily) {
      case ProductFamily.PSS:
        return this.subscriptions?.PSS?.[0]?.package?.products?.[0]?.price?.product?.id === Account.trialPSSProductId;

      case ProductFamily.PSF:
        return this.subscriptions?.PSF?.[0]?.package?.products?.[0]?.price?.product?.id === Account.trialPSFProductId;

      default:
        return false;
    }
  }

  isNotBundleProductFamily(): boolean {
    return !this.subscriptions?.BUNDLE?.length;
  }

  setSubscriptions(
    psfPlan: IBillingInfo[],
    pssPlan: IBillingInfo[],
    saPlan: IBillingInfo[],
    bundlePlan: IBillingInfo[],
    cpPlan: IBillingInfo[]
  ): void {
    this.subscriptions = {
      SA: saPlan,
      PSS: pssPlan,
      PSF: psfPlan,
      BUNDLE: bundlePlan,
      CP: cpPlan,
    };
  }

  getShortUserInfo(): IUserBillingInfo {
    return {
      id: this.pxId.toString(),
      name: this.fullName,
      email: this.email,
      SAPlan: this.getSubscriptionNameByProductFamily(ProductFamily.SA),
      PSFPlan: this.getSubscriptionNameByProductFamily(ProductFamily.PSF),
      PSSPlan: this.getSubscriptionNameByProductFamily(ProductFamily.PSS),
      CPPlan: this.getSubscriptionNameByProductFamily(ProductFamily.CP),
      AABPlan: this.getSubscriptionNameByProductFamily(ProductFamily.BUNDLE),
      created: this.dateCreated,
    };
  }
}

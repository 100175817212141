import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {FavoritesPlatformEnvironment} from '@px/favorites/domain';
import {ThemeConfig} from '@px/gallery/public';
import {THEME_PRESETS_TOKEN} from '@px/psf-themes/feature-theming';
import {CollectionPlatformEnv} from '@px/shared/data-access/collection-api';
import {PlatformEnvironment} from '@px/shared/env';
import {plainToClass, plainToInstance} from 'class-transformer';
import {AppModule} from './app/app.module';
import {IPSFThemesPlatformConfig, PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';
import ThemePresets from './assets/configs/theme-presets.json';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

(async (): Promise<void> => {
  let platform: PSFThemesPlatformEnvironment;

  try {
    const env: IPSFThemesPlatformConfig = await fetch(
      `/assets/configs/environment.json?${PSFThemesPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    const overrides: Record<string, IPSFThemesPlatformConfig> = await fetch(
      `/assets/configs/overrides.json?${PSFThemesPlatformEnvironment.getQueryString()}`
    ).then(res => res.json());

    platform = plainToClass(
      PSFThemesPlatformEnvironment,
      Object.assign(Object.assign(env, overrides ?? {}), {
        production: environment.production,
      })
    );
  } catch (e) {
    console.warn('USES FALLBACK CONFIG');
    console.warn(e);
    platform = plainToClass(PSFThemesPlatformEnvironment, {});
  }

  platformBrowserDynamic([
    {provide: MATERIAL_SANITY_CHECKS, useValue: false},
    {provide: THEME_PRESETS_TOKEN, useValue: plainToInstance(ThemeConfig, ThemePresets)},
    {provide: PlatformEnvironment, useValue: platform},
    {provide: PSFThemesPlatformEnvironment, useValue: platform},
    {provide: FavoritesPlatformEnvironment, useValue: platform},
    {provide: CollectionPlatformEnv, useValue: platform},
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.warn(err));
})();

import {OverlayRef} from '@angular/cdk/overlay';
import {untilDestroyed} from '@ngneat/until-destroy';
import {ComponentRef} from '@angular/core';
import {mapTo, ReplaySubject, Subject} from 'rxjs';
import {IUiSnackbarContainer} from '../interfaces/ui-snackbar-container.interface';

export class UiSnackbarRef<C, R> {
  private result?: R;
  private overlayRefInternal: OverlayRef;
  private containerRefInternal: ComponentRef<IUiSnackbarContainer<C>>;

  readonly afterOpenedInternal$ = new Subject<void>();
  readonly afterClosedInternal$ = new ReplaySubject<R | undefined>(1);

  set containerRef(containerRef: ComponentRef<IUiSnackbarContainer<C>>) {
    this.containerRefInternal = containerRef;

    containerRef.instance.open$.subscribe(this.afterOpenedInternal$);

    containerRef.instance.closeStart$.subscribe(() => this.overlayRef?.detachBackdrop());
    containerRef.instance.closeEnd$.pipe(mapTo(this.result)).subscribe((result?: R) => {
      this.afterClosedInternal$.next(result);
      this.afterClosedInternal$.complete();
      this.finishDialogClose();
    });
  }

  set overlayRef(overlayRef: OverlayRef) {
    this.overlayRefInternal = overlayRef;

    overlayRef
      ?.detachments()
      .pipe(untilDestroyed(this, 'close'))
      .subscribe(() => {
        this.afterClosedInternal$.next(this.result);
        this.afterClosedInternal$.complete();
        this.afterOpenedInternal$.complete();
        this.overlayRef?.dispose();
      });
  }

  get overlayRef(): OverlayRef {
    return this.overlayRefInternal;
  }

  private finishDialogClose(): void {
    this.overlayRef?.dispose();
  }

  close(dialogResult?: R): void {
    this.result = dialogResult;

    this.containerRefInternal?.instance.startExit();
  }
}

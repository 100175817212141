import {inject, Injectable} from '@angular/core';
import {EmailRegistrationService} from '../infrastructure/email-registration.service';
import {BehaviorSubject, map, mapTo, Observable, of, tap, throwError} from 'rxjs';
import {IJwtPayload, ISession, ISessionProviderService, SessionProviderService} from '@px/shared/session-provider';
import {LocalStorageService} from '@px/shared-data-access-local-storage';
import jwtDecode from 'jwt-decode';
import {IJWTPayload} from '../models/jwt-payload';
import {RegistrationStatus} from '../models/registration-status';
import {IDeleteScopeContext} from '../models/delete-scope-context';

@Injectable()
export class VisitorAuthFacade extends SessionProviderService implements ISessionProviderService {
  private readonly emailRegistrationService = inject(EmailRegistrationService);
  private readonly localStorageService = inject(LocalStorageService);

  private readonly AUTH_STORAGE_ITEM_KEY = 'AUTH_TOKEN';
  private readonly refreshIdIsNotPresented = 'Refresh Id is not presented.';
  private readonly sessionIsNotStoredOrWrongFormat = 'Session is not stored or wrong format';

  private readonly sessionInternal$ = new BehaviorSubject<ISession | null>(this.getSessionFromLocalstorage());

  session$ = this.sessionInternal$.asObservable();

  get session(): ISession | null {
    return this.sessionInternal$.value;
  }

  private removeSession(): void {
    this.localStorageService.removeItem(this.AUTH_STORAGE_ITEM_KEY);
    this.sessionInternal$.next(null);
  }

  private getSessionFromLocalstorage(): ISession | null {
    const sessionRaw = this.localStorageService.getItem(this.AUTH_STORAGE_ITEM_KEY);

    try {
      return sessionRaw && JSON.parse(sessionRaw);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  updateSession(session: ISession): void {
    try {
      this.localStorageService.setItem(this.AUTH_STORAGE_ITEM_KEY, JSON.stringify(session));
      this.sessionInternal$.next(session);
    } catch (e) {
      console.warn(e);
    }
  }

  registerVisitor(email: string, imageCollectionId: string): Observable<void> {
    return this.emailRegistrationService.registerEmail(email, {imageCollectionId}).pipe(
      tap(session => this.updateSession(Object.assign(session, {email}))),
      map(() => undefined)
    );
  }

  confirm(confirmationUrl: string, collectionId: string): Observable<void> {
    return this.emailRegistrationService.registerConfirm(confirmationUrl, collectionId).pipe(map(() => undefined));
  }

  confirmComplete(code: string): Observable<void> {
    return this.emailRegistrationService.registerConfirmComplete(code).pipe(
      tap(session => this.updateSession(session)),
      mapTo(undefined)
    );
  }

  deleteYourself(deleteScope: IDeleteScopeContext): Observable<boolean> {
    return this.emailRegistrationService.deleteVisitor(this.getEmail(), deleteScope).pipe(
      tap(() => this.logOut()),
      mapTo(true)
    );
  }

  refresh(): Observable<void> {
    if (this.session) {
      if (this.session.refreshId) {
        return this.emailRegistrationService.visitorRefresh(this.session.refreshId).pipe(
          tap(session => this.updateSession(session)),
          map(() => undefined)
        );
      }
      return throwError(() => this.refreshIdIsNotPresented);
    }
    return throwError(() => this.sessionIsNotStoredOrWrongFormat);
  }

  logOut(): void {
    this.removeSession();
  }

  hasSession(): boolean {
    return !!this.session?.token;
  }

  isConfirmed(): boolean {
    if (this.session?.token) {
      try {
        const jwtPayload: IJWTPayload = jwtDecode(this.session.token);
        return jwtPayload.status === RegistrationStatus.CONFIRMED;
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  getEmail(): string {
    if (this.session?.token) {
      try {
        const jwtPayload: IJWTPayload = jwtDecode(this.session.token);
        return jwtPayload.email;
      } catch (e) {
        return '';
      }
    }

    return '';
  }

  getId(): string {
    if (this.session?.token) {
      try {
        const jwtPayload: IJwtPayload = jwtDecode(this.session.token);
        return jwtPayload.id;
      } catch (e) {
        return '';
      }
    }

    return '';
  }

  getUserId(): string | null {
    if (this.session?.token) {
      try {
        const jwtPayload: IJWTPayload = jwtDecode(this.session.token);
        return jwtPayload.id ?? null;
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  getRoles(): string[] {
    if (!this.session?.token) {
      return [];
    }

    try {
      const jwtPayload: IJWTPayload = jwtDecode(this.session.token);

      return jwtPayload.roles;
    } catch (e) {
      return [];
    }
  }

  getSessionToken(): string | undefined {
    return this.session?.token;
  }

  handleNoSession(): Observable<boolean> {
    return of(true);
  }
}

import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, inject, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import type {ApolloClientOptions, NormalizedCacheObject} from '@apollo/client';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {caIcons, mdsProvideIcons, pxIcons} from '@pui/components/icon-core';
import {AccountDomainModule} from '@px/account/domain';
import {WINDOW_PROVIDER} from '@px/cdk/window';
import {CollectionArchiveDomainModule} from '@px/collection-archive/domain';
import {BACKEND_CLIENT_ID} from '@px/email-registration/domain';
import {FavoritesDomainModule} from '@px/favorites/domain';
import {HiddenPhotosDomainModule} from '@px/hidden-photos/domain';
import {AuthGqlService, PhotographerAuthApiModule} from '@px/photographer-auth/api';
import {
  PHOTOGRAPHER_AUTH_API,
  PHOTOGRAPHER_BACKEND_CLIENT_ID,
  PhotographerAuthDomainModule,
} from '@px/photographer-auth/domain';
import {PhotographerAuthFeatureAuthModule} from '@px/photographer-auth/feature-auth';
import {FeatureThemingModule} from '@px/psf-themes/feature-theming';
import {
  BACKEND_CONTEXT,
  BackendContextService,
  SharedDataAccessBackendModule,
} from '@px/shared-data-access-backend-context';
import {LOCAL_STORAGE_SALT} from '@px/shared-data-access-local-storage';
import {ScopeInterceptor} from '@px/shared/api';
import {BrowserService} from '@px/shared/browser';
import {
  ApolloOptionResolverBatchService,
  ApolloOptionsModule,
  ApolloOptionsResolverService,
} from '@px/shared/data-access/apollo-options';
import {DATA_DOG_SETUP_INITIALIZER, DataDogService} from '@px/shared/data-access/data-dog';
import {ExperimentalKeyValueStorageService} from '@px/shared/data-access/experimental-key-value-storage';
import {GraphqlErrorIdentifierModule} from '@px/shared/data-access/graphql-error-identifier';
import {GridConfigModule} from '@px/shared/data-access/grid-config';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {SentryErrorHandlerService, SentryModule} from '@px/shared/data-access/sentry';
import {SessionStorageModule} from '@px/shared/data-access/session-storage';
import {
  CONFIG_CAT_ASYNC_INITIALIZER,
  ConfigCatAsyncProvider,
  ConfigCatFeatureFlagsConfiguratorService,
  EnvModule,
  FEATURE_FLAGS_CONFIGURATOR,
  Languages,
  PlatformEnvironment,
  SERVICE_WORKER_SKIP_INTERCEPTOR,
} from '@px/shared/env';
import {GQL_TOKEN_INTERCEPTOR, SharedSessionProviderModule} from '@px/shared/session-provider';
import {TranslateMessageFormatCompiler} from '@px/shared/translate-message-format-compiler';
import {UiCaSnackbarContainerComponent, UiCaSnackbarContainerModule} from '@px/ui/components/ca/snackbar-container';
import {UiSnackbarModule} from '@px/ui/components/shared/snackbar';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MaintenanceModeInterceptor} from './maintenance/maintenance-mode.interceptor';
import {IS_MAINTENANCE_MODE, MaintenanceService} from './maintenance/maintenance.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found/page-not-found.component';
import {PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';
import {IframeLocalstorageServiceService} from './services/iframe-localstorage-service.service';
import {UnsupportedBrowserModule} from './unsupported-browser/unsupported-browser.module';

export const createTranslateLoader = (
  http: HttpClient,
  platformEnvironment: PSFThemesPlatformEnvironment
): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?${platformEnvironment.getQueryString()}`);
};

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UnsupportedBrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: Languages.en,
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, PSFThemesPlatformEnvironment],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && !PlatformEnvironment.CYPRESS_IS_CI,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SentryModule,
    ApolloModule,
    ApolloOptionsModule,
    PhotographerAuthApiModule,
    PhotographerAuthFeatureAuthModule,
    SharedDataAccessBackendModule,
    UiCaSnackbarContainerModule,
    UiSnackbarModule.forRoot(UiCaSnackbarContainerComponent),
    FavoritesDomainModule,
    CollectionArchiveDomainModule,
    SharedSessionProviderModule,
    GraphqlErrorIdentifierModule,
    EnvModule,
    AccountDomainModule,
    SessionStorageModule,
    HiddenPhotosDomainModule,
    GridConfigModule,
    FeatureThemingModule,
    PhotographerAuthDomainModule.forRoot(),
  ],
  providers: [
    DataDogService,
    BrowserService,
    ConfigCatAsyncProvider,
    IframeLocalstorageServiceService,
    ExperimentalKeyValueStorageService,
    GQL_TOKEN_INTERCEPTOR,
    WINDOW_PROVIDER,
    SERVICE_WORKER_SKIP_INTERCEPTOR,
    CONFIG_CAT_ASYNC_INITIALIZER,
    DATA_DOG_SETUP_INITIALIZER,
    {
      provide: FEATURE_FLAGS_CONFIGURATOR,
      useClass: ConfigCatFeatureFlagsConfiguratorService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScopeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceModeInterceptor,
      multi: true,
    },
    {
      provide: IS_MAINTENANCE_MODE,
      useFactory: (maintenanceService: MaintenanceService): Observable<boolean> =>
        maintenanceService.isMaintenanceMode$,
      deps: [MaintenanceService],
    },
    {
      provide: BACKEND_CONTEXT,
      useExisting: BackendContextService,
    },
    {
      provide: BACKEND_CLIENT_ID,
      useFactory: (): string => inject(PSFThemesPlatformEnvironment).PX_OAUTH_CLIENT_ID ?? '',
    },
    {provide: PHOTOGRAPHER_AUTH_API, useClass: AuthGqlService},
    {
      provide: PHOTOGRAPHER_BACKEND_CLIENT_ID,
      useFactory: (): string => inject(PSFThemesPlatformEnvironment).PX_OAUTH_CLIENT_ID ?? '',
    },
    {
      provide: PRODUCT_FAMILY,
      useValue: ProductFamily.PSF,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (): ApolloClientOptions<NormalizedCacheObject> => {
        return inject(PSFThemesPlatformEnvironment).GQL_BATCH
          ? inject(ApolloOptionResolverBatchService).resolve()
          : inject(ApolloOptionsResolverService).resolve();
      },
    },
    {provide: ErrorHandler, useClass: SentryErrorHandlerService},
    {
      provide: LOCAL_STORAGE_SALT,
      useFactory: (): string => {
        return inject(PSFThemesPlatformEnvironment).APP_NAME;
      },
    },
    mdsProvideIcons({...pxIcons, ...caIcons}),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly platform: PSFThemesPlatformEnvironment) {
    this.platform.printVersion();
  }
}

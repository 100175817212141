import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedDataAccessBackendModule} from '@px/shared-data-access-backend-context';
import {EmailRegistrationService} from './infrastructure/email-registration.service';
import {VisitorAuthFacade} from './application/visitor-auth-facade.service';
import {SharedDataAccessLocalStorageModule} from '@px/shared-data-access-local-storage';

@NgModule({
  imports: [CommonModule, SharedDataAccessBackendModule, SharedDataAccessLocalStorageModule],
})
export class EmailRegistrationDomainModule {
  static forRoot(): ModuleWithProviders<EmailRegistrationDomainModule> {
    return {
      ngModule: EmailRegistrationDomainModule,
      providers: [EmailRegistrationService, VisitorAuthFacade],
    };
  }
}
